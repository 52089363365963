import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import {
  motion,
  animate,
  useAnimation,
  useSpring,
  Variants
} from "framer-motion";

function useDot() {
  const x = useSpring(0);
  const y = useSpring(0);
  const scale = useSpring(1);
  const controls = useAnimation();

  return { x, y, scale, controls } as const;
}

function Playground() {
  function handlePause() {}

  function handleComplete() {}

  async function handleIdle() {}

  async function handleStart() {}

  function handleReset() {}

  return (
    <Wrapper>
      <svg viewBox="0,0 10,10" width="200px" height="200px">
        <Track
          fill="none"
          strokeWidth="0.25"
          d="M 5 5 m -4, 0 a 4,4 0 1,0 8,0 a 4,4 0 1,0 -8,0"
        />

        <Marker r="1" fill="orange" />
      </svg>
      <ButtonsContainer>
        <Button onClick={handlePause}>pause</Button>
        <Button onClick={handleStart}>start</Button>
        <Button onClick={handleReset}>reset</Button>
      </ButtonsContainer>
    </Wrapper>
  );
}

export default Playground;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
`;

const move = keyframes`
  100% { 
    motion-offset: 100%;
    offset-distance: 100%;
  }
`;

const Track = styled.path`
  stroke: #ccc;
`;

const Marker = styled.circle`
  motion-path: path("M 5 5 m -4, 0 a 4,4 0 1,0 8,0 a 4,4 0 1,0 -8,0");
  offset-path: path("M 5 5 m -4, 0 a 4,4 0 1,0 8,0 a 4,4 0 1,0 -8,0");
  animation: ${move} 3s linear infinite;
`;

const DotsContainer = styled(motion.div)`
  --base-size: 84px;
  --purple-size: calc(var(--base-size) * 1.85);
  --pink-size: calc(var(--base-size) * 0.75);
  --blue: linear-gradient(50.66deg, #7ecefd 20.28%, #91c2ff 94.25%);
  --purple: linear-gradient(140.66deg, #b3b3ff 18.64%, #d9a1f2 92.61%);
  --pink: linear-gradient(230.66deg, #f78dd3 5.75%, #ff7da9 79.72%);
  --orange: linear-gradient(320.66deg, #ff777a 7.39%, #fc7d49 81.36%);

  display: grid;
  grid-gap: 0.5rem;
  justify-content: center;
`;

const Dot = styled(motion.div)`
  width: var(--base-size);
  height: var(--base-size);
  border-radius: 50%;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
`;

const BlueDot = styled(Dot)`
  background: var(--blue);
  grid-area: span 2 / span 2;
`;

const PurpleDot = styled(Dot)`
  background: var(--purple);
  width: var(--purple-size);
  height: var(--purple-size);
  margin: 0 auto;
  z-index: 1;
`;

const PinkDot = styled(Dot)`
  background: var(--pink);
  width: var(--pink-size);
  height: var(--pink-size);
`;

const OrangeDot = styled(Dot)`
  background: var(--orange);
  margin: 0 1rem;
`;

const ButtonsContainer = styled.div`
  position: fixed;
  top: 25%;
  right: 15%;
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  background: none;
  outline: none;
  border: none;
  font-size: 1.6rem;
  padding: 0.5rem 2rem;
  border-radius: 9999px;
  background: linear-gradient(to right, cornflowerblue, slateblue);
  color: white;
  cursor: pointer;
`;
